/* IE10+ CSS styles go here */

@mixin ie11-edge18-styles {  
	/*body { background-color: red !important; }*/
	
	@media (min-aspect-ratio: 16/9)  {
		html {
			font-size: calc((9 / 16) * var(--vh, 1vh));
		}

		.Sensikit,
		.Error {
			height: 100vh; /* Fallback for browsers that do not support Custom Properties */
			height: calc(var(--vh, 1vh) * 100);

			width: calc((16 / 9) * var(--vh, 1vh) * 100);
		}
	}

	@media (max-aspect-ratio: 16/9) {
		html {
			font-size: calc((9 / 29) * 1vw);
		}

		.Sensikit,
		.Error {
			height: calc((9 / 16) * 100vw);
			margin-top: calc((var(--vh, 1vh) * 100 - (9 / 16) * 100vw) / 2);

			width: 100vw;
		}
	}

	

	.LogoEntreprise.Left {
		
		margin-top: 11.2rem;
		margin-left: 0;
	}
	
	.SplashscreenFooter {
		padding-top: 34.3rem;
	}
	
	.SplashscreenFooter button {
		display: block;
		margin: 0 auto;
		width: 65rem;
		height: 11rem;
		
		
	}
	
	.StartButton {
		display: block;
		float: left;
	}
	
	/* mode 1 */
	
	.PopupContainer {
		display:block;
		background-color: rgba(56, 68, 67, 0.55);
		
	}
	
	.Popup {
		margin: 0 0;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	
	.BtnOk {
		display: block;
		height: 25rem;
		text-align: center;
		padding-top: 0;
		
	}
	
	.BtnInfo {
		display: block;
		height: 18rem;
		text-align: center;
		padding-top: 7rem;
		
	}
	
	.Ronds {
		display: block;
		left: 50%;
		transform: translateX(-50%);
	}
	.Rond {
		display: inline-block;
		margin-left: 10rem;
		margin-right: 7rem;
		margin-bottom: 2rem;
	}
	.Rond.RondGros {
		margin-bottom: 0;
	}
	.Rond:first-child {
		margin-left: 7rem;
	}
	
	.QuizVertCards {
		display: block;
		width: 160rem;
		height: 45rem;
		margin: 0 auto;
	}
	
	.QuizVertCard, .CardGrise {
		position: relative;
		display:block;
		float: left;
		margin: 0 5rem 5rem 0;
		height: 17rem;
	}
	.QuizVertCard {
		cursor: move;
	}
	.QuizVertCardImage {
		display: block;
		float: left;
		height: 13rem;
		width: 11rem;
		margin-top: 1rem;
	}
	.QuizVertCardImageTitle {
		position: absolute;
		top: 50%;
		left: 21rem;
		transform: translateY(-50%);
	}
	.QuizVertReponses {
		
	}
	.QuizVertCardReponse {
		position: relative;
		display: block;
		margin-right: 6rem;
	}
	.QuizVertCardReponse:last-child {
		margin-right: 0;
	}
	.QuizVertCardStat {
		display: block;
		margin-top: -9rem;
		text-align: center;
		height: 7rem;
		padding-top: 1rem;
	}
	.QuizVertCardStat.DragActive {
		height: 8rem;
		padding-top: 0rem;
	}
	.QuizVertCardReponseList {
		height: 25rem;
	}
	
	.QuizVertCardTitle.DragActive, .QuizVertCardStat.DragActive, .QuizVertCardDescription.DragActive {
		text-align: center;
		border: 0.75rem dashed rgba(112, 112, 112, 0.29);
	}
	.QuizVertCardTitle.DragDone, .QuizVertCardStat.DragDone, .QuizVertCardDescription.DragDone {
		cursor: pointer;
	}
	.QuizVertCardTitle {
		position: relative;
	}/*
	.QuizVertCardTitle.DragDone:hover::after {
		position: absolute;
		top: -1rem; left: -1rem;
		display: block;
		background-color: rgba(0,0,0,0.36);
		text-align: center;
		padding-top: 0rem;
		height: 8rem;
	}
	.QuizVertCardStat.DragDone:hover::after {
		position: absolute;
		top: -1rem; left: -1rem;
		display: block;
		background-color: rgba(0,0,0,0.36);
		text-align: center;
		padding-top: 0rem;
		height: 9.5rem;
	}
	.QuizVertCardDescription.DragDone:hover:after {
		display: block;
		background-color: rgba(0,0,0,0.36);
		text-align: center;
		padding-top: 3rem;
		height: 17rem;
		content: "↑";
		border-radius: 4rem;
	}
	*/
	
	
	.BgReponse {
		display: block;
	}
	.BgReponse .FondCorrect, .BgReponse .FondFaux {
		display: block;
		height: 12.5rem;
	}
	.BgReponse .BgBlanc {
		display: block;
		height: 1.5rem;
	}
	.BgReponse .BgBlancCasse {
		display: block;
		height: 30rem;
		text-align: center;
	}
	.BgReponse .BgBlancCasse .Pouce {
		margin-top: 3rem;
	}
	.BgReponse.FondFaux .BgBlancCasse .Pouce {
		margin-top: 4.5rem;
	}
	
	.Bravo {
		text-align: center;
	}
	.BonneReponse {
		text-align: center;
	}
	
	.Mode-rate .QuizVertCardImageTitle {
		left: 5rem;
		width: 38rem;
		text-align: center;
	}
	.Mode-behaviors .QuizVertCards {
		width: 220rem;
	}
	.Mode-behaviors .QuizVertCard {
		width: 65rem;
	}
	.Mode-behaviors .CardGrise {
		width: 65rem;
	}
	.Mode-behaviors .QuizVertCardImageTitle {
		left: 3rem;
		width: 60rem;
		text-align: left;
	}
	.Mode-behaviors .QuizVertCardImageTitle ul {
		
		
	}
	.Mode-behaviors .QuizVertCardImageTitle li {
		
	}
	.Mode-behaviors .QuizVertCards :not(.Dragging) ul li:nth-of-type(2):after {
		content: none;
	}
	.Mode-behaviors .QuizVertCards :not(.Dragging) ul li:nth-of-type(n+3) {
		display: list-item;
	}
	
	
	/* Mode 2 */
	.QuestionEnCours {
		padding-top: 2rem;
		height: 10rem;
	}
	
	.QuizRoseReponses {
		margin: 0 auto;
		display: block;
		text-align: center;
	}
	.QuizRoseReponses button {
		width: 40rem;
		margin-right: 5rem;
	}
	.QuizRoseReponses button:last-child {
		margin-right: 0;
	}
	
	
	
	/* Mode 3 */
	.QuizOrangeReponseContainer {
		background: rgba(245, 245, 245, 0.70);
	}
	.QuizOrangeReponse.DragNone {
		text-align: center;
		
	}
	.QuizOrangeReponse.DragNone::after {
		padding-top: 18rem;
	}
	.QuizOrangeReponse.DragActive::after {
		padding-top: 12rem;
		text-align: center;
	}
	.QuizOrangeSentence {
		cursor: move;
	}
	.BoutonsOrange {
		display: block;
		text-align: center;
	}
	.BoutonsOrange .BoutonOrangeValider {
		margin-left: 5rem;
	}
	
	/* mode 4 */
	.QuizBleuReponse input:checked + label:after {
		text-align: center;
	}
	
	/* mode 5 */
	.QuizVioletCards {
		margin: 0 auto;
		display: block;
		width: 240rem;
		cursor: move;
	}
	.QuizVioletCard, .CardVioletGrise {
		display: block;
		float: left;
		margin-right: 5rem;
		width: 49rem;
	}
	.QuizVioletCard:last-child {
		margin-right: 0;
	}
	
	.QuizVioletReponses {
		position: relative;
		display: block;
	}
	.QuizVioletReponsesContainer{
		display: block;
		width: 240rem;
	}
	.QuizVioletCardReponse {
		display: block;
		float: left;
		margin-right: 5rem;
		width: 55rem;
	}
	.QuizVioletCardReponse:last-child {
		margin-right: 0;
	}
	.QuizVioletReponse.DragActive {
		border: 0.75rem dashed rgba(112, 112, 112, 0.29);
		text-align: center;
	}
	/*
	.QuizVioletReponse.DragDone:hover::after {
		position: absolute;
		top: -1rem; left: -1rem;
		display: block;
		background-color: rgba(0,0,0,0.36);
		text-align: center;
		padding-top: 6rem;
		height: 25.7rem;
		width: 52rem;
		border-radius: 5rem;
	}
	*/
	
	.QuizVioletEnonce {
		min-height: 27rem;
	}

	.ResultatContainer .QuizVioletReponses {
		display: block;
		margin: 0 auto;
		width: 240rem;
	}
	.ResultatContainer .QuizVioletReponses .QuizVioletReponsesContainer {
		display: block;
		float: left;
		margin-right: 5rem;
		width: 55rem;	
	}
	.ResultatContainer .QuizVioletReponses .QuizVioletReponsesContainer:last-child {
		margin-right: 0;
	}
	
	.QuizVioletReponse {
		padding:3rem;
		width: 44rem;
		height: 24rem;
	}
	
	
	/* Fin */
	.BlocHautEnd {
		display: block;
		height: 105rem;
	}
	.Felicitations {
		display: block;
		padding: 11rem 0 10rem 0;
		height: 18rem;
		text-align: center;
	}
	.LogoContainer {
		display: block;
	}
	.LogoEntreprise {
		margin: 0 auto;
		width: 190rem;
		height: 46rem;
	}
	.Remerciement {
		margin-top: 9rem;
		text-align: center;
	}
	
	.EndscreenFooter {
		position: relative;
		display: block;
		height: 65rem;
	}
	.Memo {
		display: block;
		margin: 13.8rem auto 0 auto;
		width: 130rem;
	}
	.LogoSensiPlay {
		position: absolute;
		top:8.6rem; 
		left:17rem;
		width: 45rem;
		height: auto;
	}
	.LogoSensiPlay img {
		margin:0; 
		width: 100%;
		height: auto;
	}
	.SignatureContainer {
		position: absolute;
		top:36.1rem; 
		right:11rem;
		width: 68rem;
		height: auto;
	}
	.SignatureContainer .JLO {
		margin: 0;
		width: 100%;
		height: auto;
	}
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

	@include ie11-edge18-styles;
}

@supports (-ms-ime-align:auto) {
	
	/*body { background-color: green; }*/
	
	@include ie11-edge18-styles;
	
	.QuizVertCard.Dragging {
		top:-1000px
	}
	.QuizVertCard3.Dragging {
        display: block;
		padding: 3.5rem 2.5rem;
		height: auto !important;
		min-height: 0;
		width: 80rem !important;
    }
	.QuizVertCard3.Dragging .QuizVertCardImageTitle {
        font-size: 3.5rem !important;
		margin:0;
		position: relative;
		top:0; left:0;
		transform: none;
    }
}