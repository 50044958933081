.QuizVertContainer > div {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: auto auto 5rem auto;
    grid-template-rows: auto auto 5rem auto;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;

    padding: 10rem 15rem 0;
    max-height: 100%;

    position: relative;
}
.QuizVertContainer > div > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-columns: 1;
}
.QuizVertContainer > div > *:nth-child(2) {
    -ms-grid-row: 2;
    -ms-grid-columns: 1;
}
.QuizVertContainer > div > *:nth-child(3) {
    -ms-grid-row: 3;
    -ms-grid-columns: 1;
}
.QuizVertContainer > div > *:nth-child(4) {
    -ms-grid-row: 4;
    -ms-grid-columns: 1;
}

.QuizVertTitre {
    background: #00a370;
    font-size: 7rem;
    text-align: center;
    color: #fff;
    font-weight: bold;
    padding: 5rem;
    border-radius: 2rem;
    margin: 10rem 0 2rem;
}

.QuizVertCards {
    display: -ms-grid;
    display: grid;
    grid-template-rows: 1fr;
    -ms-grid-rows: 1fr 1fr;
    -webkit-column-gap: 5%;
    -moz-column-gap: 5%;
    column-gap: 5%;
    row-gap: 5rem;
    -ms-grid-columns: 47.5% 5% 47.5%;
    grid-template-columns: 47.5% 47.5%;
    margin-bottom: 2rem;
}

.CardGrise {
    background-color: #f5f5f5;
}

.indexImage {
    z-index: 0;
}

.Box {
    cursor: move;
}

.QuizVertCard,
.CardGrise {
    height: 25rem;
    width: 62rem;
    border-radius: 3rem;
    padding: 3rem;
    margin: 1.5rem;
    color: #534c4c;
    font-size: 4rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.16);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.16);
    font-weight: bold;
    position: relative;
    -webkit-transition: all 0.25 ease;
    -o-transition: all 0.25 ease;
    transition: all 0.25 ease;
}

.QuizVertCard {
    background: #d9e49b;
    cursor: -webkit-grab;
    cursor: grab;
}

.Mode-behaviors .QuizVertCard {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.QuizVertCardImage {
    width: 20rem;
    height: 20rem;
    margin: 1rem 1rem 1rem 2rem;
    background: #fff;
    border-radius: 2rem;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.16);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.16);
    display: -ms-grid;
    display: grid;
    grid-template-rows: 1fr;
    -ms-grid-rows: 1fr;
    -ms-grid-columns: 1fr;
    place-items: center;
    padding: 1rem;
    background-size: 10rem;
    background-position: center;
    background-repeat: no-repeat;
}
.QuizVertCardImage img {
    width: 15rem;
    height: 15rem;
}

.QuizVertCardImageTitle {
    color: #000;
    width: 100%;
    font-size: .7rem;
    max-height: 100%;
    text-align: center;
}

.QuizVertCardImageTitle ul {
    padding-left: 8rem;
    margin: 0;
}
.Mode-rate .QuizVertCardImageTitle,
.Mode-behaviors .QuizVertCardImageTitle {
    margin-right: 0;
}

.Mode-rate .QuizVertCardImageTitle {
    font-size: 13rem;
    font-weight: bold;
}

.Mode-behaviors .QuizVertCardImageTitle ul li {
    font-size: 3.5rem;
}

.Mode-behaviors .Safari .QuizVertCardImageTitle ul li {
    font-size: 3rem;
}

.QuizVertReponses {
    height: 105rem;
    margin: 10rem auto;
    max-width: 125rem;
    position: relative;
}

.QuizVertReponsesContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    overflow: hidden;
    padding-top: 10rem;
}

.QuizVertCardReponse {
    min-width: 100%;
    max-height: 100%;
    height: 110rem;
    background: rgba(223, 225, 235, 0.7);
    -webkit-box-shadow: 2rem 2rem 4rem rgba(238, 224, 224, 0.1);
    box-shadow: 2rem 2rem 4rem rgba(238, 224, 224, 0.1);
    border-radius: 7rem;
    padding-top: 10rem;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    position: relative;

    display: -ms-grid;

    display: grid;
    -ms-grid-rows: auto 1fr auto;
    grid-template-rows: auto 1fr auto;
    -ms-grid-columns: 1fr;

    &--Vrai {
        background: lighten(#85b8ce, .25);
    }

    &--Faux {
        background: rgba(223, 225, 235, 0.7);
    }
}

.FondCorrect .QuizVertCardReponse--Vrai  {
    background: rgba(223, 225, 235, 0.7);
}

.QuizVert1.DragActive .QuizVertCardTitle,
.QuizVert2.DragActive .QuizVertCardStat,
.QuizVert3.DragActive .QuizVertCardDescription {
    border: 0.75rem dashed #7070704a !important;
}

.QuizVert1.DragActive .QuizVertCardTitle:not(.DragDone),
.QuizVert2.DragActive .QuizVertCardStat:not(.DragDone),
.QuizVert3.DragActive .QuizVertCardDescription:not(.DragDone) {
    background-color: #e2e2e2;
    color: #0000005c;
}

.QuizVertCardStat {
    position: absolute;
    background: #fff;
    left: 50%;

    margin-left: -12.5rem;
    margin-top: -8.5rem;

    width: 25rem;
    height: 15rem;

    border-radius: 4rem;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.16);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.16);
    font-size: 8rem;
    font-weight: 900;
    display: -ms-grid;
    display: grid;
    grid-template-rows: 1fr;
    -ms-grid-rows: 1fr;
    -ms-grid-columns: 1fr;
    place-items: center;
    color: #534c4c;
}
.QuizVertCardStat span {
    font-size: 10rem;
    font-weight: 500;
}

.QuizVertCardStat.DragActive {
    border: 0.75rem dashed #7070704a;
    color: #909090;
}
.QuizVertCardStat.DragDone,
.QuizVertCardStat.DragGreen {
    background: #00a370;
    color: #fff;
    border: 0.75rem solid #00a370;
}

.QuizVertCardTitle,
.QuizVertCardReponseList {
    width: 85%;
    margin: 3rem auto;
}

.QuizVertCardTitle {
    position: relative;
    background: #fff;
    height: 12rem;
    border-radius: 3rem;
    -webkit-box-shadow: 0 0 2px #00000005;
    box-shadow: 0 0 2px #00000005;
    font-size: 7rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #534c4c;
    border: 0.75rem solid #fff;
    text-align: center;
}

.QuizVertCardTitle.DragActive {
    border: 0.75rem dashed #7070704a;
}

.QuizVertCardTitle span,
.QuizVertCardDescription span {
    font-size: 12rem;
    color: #d1d1d1;
    font-weight: 500;
}

.QuizVertCardReponseList ul {
    padding-left: 7rem;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    -webkit-column-gap: 4rem;
    -moz-column-gap: 4rem;
    column-gap: 4rem;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.QuizVertCardReponseList {
    & > ul > li, & > p {
        font-size: 4.5rem;
        color: #7a7a7a;
        font-weight: bold;
        margin: 0;
    }

    & > p {
        text-align: center;
    }
}

.QuizVertCardDescription {
    border-radius: 4rem;
    background-color: #fff;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.16);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.16);
    width: 80%;
    height: 40rem;
    margin: 7.5rem auto 10rem;
    display: -ms-grid;
    display: grid;
    grid-template-rows: 1fr;
    -ms-grid-rows: 1fr;
    -ms-grid-columns: 1fr;
    place-items: center;
    font-size: 4rem;
    font-weight: 500;

    position: relative;
}

.QuizVertCardDescription.DragActive {
    color: #e2e2e2;
    border: 0.75rem dashed #7070704a;
}
/*
@media (hover: hover) and (pointer: fine) {
    
	.QuizVertCardDescription.DragDone:hover::after {
        width: 100%;
        height: 100%;
        position: absolute;
        background: #0000005c;
        content: "↑";
        font-size: 15rem;
        color: #333;
        display: -ms-grid;
        display: grid;
        grid-template-rows: 1fr;
        -ms-grid-rows: 1fr;
        -ms-grid-columns: 1fr;
        place-items: center;
        border-radius: 4rem;
    }
	
}*/
.QuizVertCardDescription.DragDone:hover::after {
    width: 100%;
    height: 17rem;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.36);
    content: "↑";
    font-size: 15rem;
    color: #333;
    display: block;
    border-radius: 4rem;
    text-align: center;
    padding-top: 3rem;
}

/*
.QuizVertCardTitle.DragDone:hover::after {
    width: 108rem;
    height: 14rem;
    position: absolute;
    background: #0000005c;
    content: "↑";
    font-size: 12rem;
    color: #333;
    display: -ms-grid;
    display: grid;
    grid-template-rows: 1fr;
    -ms-grid-rows: 1fr;
    -ms-grid-columns: 1fr;
    place-items: center;
    border-radius: 3rem;
}
*/
.QuizVertCardTitle.DragDone:hover::after {
    width: 108rem;
    height: 14rem;
    position: absolute;
    top: -1rem;
    left: -1rem;
    background-color: rgba(0, 0, 0, 0.36);
    content: "↑";
    font-size: 12rem;
    color: #333;
    display: block;
    border-radius: 3rem;
    text-align: center;
    padding-top: 0;
}
/*
.QuizVertCardStat.DragDone:hover::after {
    width: 27rem;
    height: 16rem;
    position: absolute;
    background: #0000005c;
    content: "↑";
    font-size: 10rem;
    color: #333;
    display: -ms-grid;
    display: grid;
    grid-template-rows: 1fr;
    -ms-grid-rows: 1fr;
    -ms-grid-columns: 1fr;
    place-items: center;
    border-radius: 4rem;
}
*/
.QuizVertCardStat.DragDone:hover::after {
    width: 27rem;
    height: 17rem;
    position: absolute;
    top: -0.5rem;
    left: -1rem;
    background-color: rgba(0, 0, 0, 0.36);
    content: "↑";
    font-size: 10rem;
    color: #333;
    display: block;
    border-radius: 3rem;
    padding-top: 0;
    text-align: center;
}
.QuizVertCardDescription ul {
    padding-left: 10rem;
    color: #534c4c;
}

.BoutonVertValider {
    background: transparent -o-linear-gradient(260deg, #b1d008 0%, #058900 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(190deg, #b1d008 0%, #058900 100%) 0% 0% no-repeat padding-box;
}

.PageVertFooter {
    background: -o-linear-gradient(260deg, #ecf1d0 0%, #d9e49b 50%, #ecf1d0 100%);
    background: linear-gradient(190deg, #ecf1d0 0%, #d9e49b 50%, #ecf1d0 100%);
}

.QuizVertCard.Dragging {
    z-index: 99;
    position: absolute;
    width: 100rem;
    font-size: 7rem;
    height: 35rem;
    display: flex;
}
.QuizVertCard1.Dragging .QuizVertCardImage {
    width: 25rem;
    height: 25rem;
    background-size: 12.5rem;
}
.QuizVertCard1.Dragging .QuizVertCardImageTitle {
    font-size: 4rem;
}

.QuizVertCard2.Dragging {
    width: 100rem;
    height: 40rem;
}

.QuizVertCard2.Dragging .QuizVertCardImageTitle {
    margin-right: 0;
    font-size: 15rem !important;
}

.QuizVertCard.Dragging .CardDrag {
    display: none;
}

.QuizVertCard3.Dragging {
    width: 100rem;
    height: auto;
    overflow: hidden;
    padding: 12rem;
}

.QuizVertCard3 .QuizVertCardImageTitle {
    overflow: hidden;
}
.QuizVertCard3.Dragging .QuizVertCardImageTitle ul {
    padding-left: 6rem;
    list-style-type: none;
}
.QuizVertCard3.Dragging .QuizVertCardImageTitle ul li {
    font-size: 6rem;
    display: list-item;
    text-align: match-parent;
}
.QuizVertCard3.Dragging .QuizVertCardImageTitle ul li::before {
    content: "•";
    display: inline-block;
    margin-right: 3rem;
    line-height: 1rem;
}
.QuizVertCards:not(.Safari) *:not(.Dragging) ul li:nth-of-type(n + 3) {
    display: none;
}
.QuizVertCards:not(.Safari) .Dragging ul li:nth-of-type(n + 3) {
    display: block;
}

.QuizVertCards:not(.Safari) *:not(.Dragging) ul li:nth-of-type(2)::after {
    content: "...";
    display: block;
}
.QuizVertCards:not(.Safari) .Dragging ul li:nth-of-type(2)::after {
    content: "";
    display: none;
}

@mixin quiz-vert-landscape-styles {
    .QuizVertContainer > div {
        padding: 5rem;
    }

    .QuizVertTitre {
        margin: 0rem 30rem 2rem;
    }
    .QuizVertCards {
        -ms-grid-columns: 17% 17% 17%;
        grid-template-columns: 15% 15% 15%;
        -webkit-column-gap: 2%;
        -moz-column-gap: 2%;
        column-gap: 2%;
        place-content: center;
        row-gap: 1.5rem;
        margin-bottom: 4rem;
    }
    .QuizVertCard,
    .CardGrise {
        height: 16rem;
        width: 45rem;
        padding: 0.5rem 1.5rem;
        font-size: 3rem;
    }
    .QuizVertCard.Dragging {
        display: block;
        width: 45rem !important;
        height: 19rem !important;
        font-size: 5rem;
    }
    .QuizVertCard1.Dragging .QuizVertCardImage {
        width: 10rem;
        height: 15rem;
        background-size: 10rem;
    }
    .QuizVertCard1.Dragging .QuizVertCardImageTitle {
        position: absolute;
        top: 50%;
        left: 8rem;
        transform: translateY(-50%);
        font-size: 3rem;
    }

    .QuizVertCard2.Dragging {
        height: 20rem !important;
    }

    .QuizVertCard2.Dragging .QuizVertCardImageTitle {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        font-size: 12rem !important;
        text-align: center;
    }

    .Safari .QuizVertCard3 {
        height: 22rem !important;
    }

    .QuizVertCard3.Dragging .QuizVertCardImageTitle ul li {
        font-size: 3rem;
    }

    .QuizVertCard3.Dragging {
        overflow: hidden;
        padding: 2.5rem;
        height: auto !important;
    }

    .QuizVertCardImage {
        width: 12rem;
        height: 12rem;
        background-size: 7rem;
    }
    .QuizVertCardImage img {
        width: 10rem;
        height: 10rem;
    }

    .QuizVertCardImageTitle {
        font-size: 3rem;
    }

    .QuizVertCardImageTitle ul {
        padding-left: 6rem;
    }

    .Mode-rate .QuizVertCardImageTitle {
        font-size: 10rem;
    }

    .QuizVertReponses {
        height: 75rem;
        margin: 5rem auto 0 auto;
        max-width: 100%;
    }

    .QuizVertReponsesContainer {
        padding-top: 5rem;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .QuizVertCardStat {
        margin-left: -7.5rem;
        margin-top: -5rem;
        width: 15rem;
        height: 8rem;
        border-radius: 3rem;
        font-size: 5rem;
    }
    .QuizVertCardStat span {
        font-size: 7rem;
    }

    .QuizVertCardReponse {
        padding-top: 5rem;
        width: 45rem;
        min-width: 45rem;
        height: 71rem;
        margin: 0 2.5rem 0 2.5rem !important;
    }

    .QuizVertCardTitle {
        margin: 4rem auto 0rem;
    }
    .QuizVertCardReponseList {
        margin: 2rem auto;
    }

    .QuizVertCardTitle {
        height: 6rem;
        font-size: 2.8rem;
    }

    .QuizVertCardTitle span {
        font-size: 8rem;
    }

    .QuizVertCardReponseList ul {
        padding-left: 0rem;
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;

        list-style-type: none;
    }

    .QuizVertCardReponseList {
        & > ul > li, & > p {
            font-size: 3rem;
        }
    }

    .QuizVertCardDescription {
        height: 20rem;
        margin: 0 auto 6rem;
    }
    .QuizVertCardDescription ul {
        padding-left: 6rem;
        font-size: 1.5rem;
    }

    .Mode-behaviors .QuizVertCardImageTitle ul li {
        font-size: 2rem;
    }
    .Mode-behaviors .Safari .QuizVertCardImageTitle ul li {
        font-size: 1.75rem;
    }

    .QuizVertCardDescription.DragDone:hover::after {
        font-size: 10rem;
    }

    .QuizVertCardTitle.DragDone:hover::after {
        width: 40rem;
        height: 8rem;
        font-size: 6rem;
    }

    .QuizVertCardStat.DragDone:hover::after {
        width: 17rem;
        height: 9rem;
        font-size: 7rem;
    }
}

@media (orientation: landscape), (orientation: portrait) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @include quiz-vert-landscape-styles;
}

@media (orientation: portrait)  {
    .QuizVertCardImageTitle {
        font-size: 4rem;
    }

    .QuizVertCardReponseList ul:has(li:only-child) {
        padding-left: 0;
        column-count: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
        list-style-type: none;
    }
}

@supports (-ms-ime-align: auto) {
    @include quiz-vert-landscape-styles;
}
