.FondCorrect {
    background-color: #85b8ce;
}

.BgReponse {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: 17rem 1.5rem 45rem 1.5rem 1fr;
    grid-template-rows: 17rem 1.5rem 45rem 1.5rem 1fr;
    -ms-grid-columns : 1fr;
    height: 100%;
    width: 100%;
}

.Bravo {
    padding-top: 5rem;
    font-size: 14rem;
    font-weight: 900;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    place-items: center;
}

.FakeHeight {
    height: 8rem;
}

.Pouce {
    width: 30rem;
}

.BonneReponse {
    font-size: 7rem;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    place-items: center;
}

.ResultatContainer {
    width: 100%;
    height: 100%;

    position: absolute;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    place-items: center;
}

.SuivantJuste {
    background: transparent -o-linear-gradient(256deg, #85b8ce 0%, #435c67 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(194deg, #85b8ce 0%, #435c67 100%) 0% 0% no-repeat padding-box;
}

.SuivantFaux {
    background: transparent -o-linear-gradient(259deg, #d95858 0%, #950b0b 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(191deg, #d95858 0%, #950b0b 100%) 0% 0% no-repeat padding-box;
}

.ReponseBas {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.BgBlancCasse {
    background-color: #eeeeee;
    place-items: center;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
}

.BgBlanc {
    background-color: #ffffff;
}

.Blanc {
    color: #ffffff;
}

.FondFaux {
    background-color: #d95858;
}


@mixin resultat-landscape-styles {
	.BgReponse {
        -ms-grid-rows: 12.5rem 1.5rem 30rem 1.5rem 1fr;
        grid-template-rows: 12.5rem 1.5rem 30rem 1.5rem 1fr;
        -ms-grid-columns : 1fr;
    }

    .Pouce{
        width: 25rem;
    }
}

@media (orientation: landscape) , (orientation: portrait) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @include resultat-landscape-styles;
}

@supports (-ms-ime-align:auto) {
	@include resultat-landscape-styles;
}