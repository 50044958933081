.QuizVioletContainer {
    height: 100%;
}
.QuizVioletContainer > div {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: auto auto 12rem 10rem 1fr;
    grid-template-rows: auto auto 12rem 10rem 1fr;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    height: calc(100% - 10rem);
    position: relative;
    padding: 10rem 20rem;
}
.QuizVioletContainer > div > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-columns: 1;
}
.QuizVioletContainer > div > *:nth-child(2) {
    -ms-grid-row: 2;
    -ms-grid-columns: 1;
}
.QuizVioletContainer > div > *:nth-child(3) {
    -ms-grid-row: 3;
    -ms-grid-columns: 1;
}
.QuizVioletContainer > div > *:nth-child(4) {
    -ms-grid-row: 4;
    -ms-grid-columns: 1;
}
.QuizVioletContainer > div > *:nth-child(5) {
    -ms-grid-row: 5;
    -ms-grid-columns: 1;
}

.QuizVioletTitre,
.QuizVioletReponseTitre {
    background: #b5157c;
    font-size: 7rem;
    text-align: center;
    color: #fff;
    font-weight: bold;
    padding: 5rem;
    border-radius: 2rem;
    margin: 2rem 0;
}
.QuizVioletReponseTitre {
    background: #884d79;
    margin: 0;
}

.QuizVioletCards {
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -webkit-column-gap: 8%;
       -moz-column-gap: 8%;
            column-gap: 8%;
    row-gap: 5rem;
    -ms-grid-columns: 46% 8% 46%;
    grid-template-columns: 46% 46%;
    margin-bottom: 2rem;
}

.QuizVioletCard,
.CardVioletGrise {
    background: #e2d3e9;
    height: 35rem;
    width: 50rem;
    border-radius: 6rem;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .16);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .16);
    position: relative;
    font-size: 3.75rem;
    padding: 5rem;
    font-weight: 500;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    place-items: center;

    -webkit-transition: all 0.25 ease;

    -o-transition: all 0.25 ease;

    transition: all 0.25 ease;
}

.CardVioletGrise {
    background-color: #f5f5f5;
}

.SourceViolet {
    font-size: 3.5rem;
    margin: 5rem 0;
    text-align: center;
    font-weight: 700;
    color: #575757;
    font-style: italic;
}

.QuizVioletReponsesContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    overflow: hidden;
}

.ResultatContainer .QuizVioletReponsesContainer {
    margin: 20rem;
}

.QuizVioletCardReponse {
    min-width: 100%;
    max-height: 100%;
    background: #dfe1eb;
    -webkit-box-shadow: 2rem 2rem 4rem rgba(238, 224, 224, 0.1);
            box-shadow: 2rem 2rem 4rem rgba(238, 224, 224, 0.1);
    border-radius: 10rem;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    position: relative;

    display: -ms-grid;

    display: grid;
    -ms-grid-rows: auto 1fr auto;
    grid-template-rows: auto 1fr auto;
    -ms-grid-columns : 1fr;
}

.QuizVioletCardReponse.DragActive .QuizVioletReponse {
    border: 0.75rem dashed #7070704a;
}

.QuizVioletCardReponse.DragActive .QuizVioletReponse:not(.DragDone) {
    background-color: #e2e2e2;
    color: #0000005c;
}

/*
.QuizVioletReponse.DragDone:hover::after {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #0000005c;
    content: "↑";
    font-size: 15rem;
    color: #333;
    display: -ms-grid;
    display: grid;
    grid-template-rows: 1fr;
    -ms-grid-rows: 1fr;
    -ms-grid-columns: 1fr;
    place-items: center;
    border-radius: 4rem;
}
*/
.QuizVioletReponse.DragDone:hover::after {
    position: absolute;
	top: -1rem; left: -1rem;
    display: block;
    padding-top: 5rem;
	height: 88%;
	width: 104%;
    background-color: rgba(0,0,0,0.36);
    content: "↑";
    font-size: 15rem;
    color: #333;
    border-radius: 5rem;
	text-align: center;
	
}

.QuizVioletReponses {
    height: 100rem;
    position: relative;
    margin-top: 5rem;
}
.ResultatContainer .QuizVioletReponses {
    color: #534c4c;
}

.QuizVioletEnonce {
    min-height: 20rem;
    text-align: center;
    margin: 5rem 10rem;
    font-size: 6rem;
    font-weight: 500;
}

.QuizVioletReponse {
    width: 65rem;
    height: 45rem;
    background: #fff;
    -webkit-box-shadow: 2rem 2rem 4rem rgba(238, 224, 224, 0.1);
            box-shadow: 2rem 2rem 4rem rgba(238, 224, 224, 0.1);
    border-radius: 5rem;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    position: relative;
    margin: 5rem auto 7.5rem;
    font-size: 5rem;
    font-weight: 500;
    padding: 2rem;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    place-content: center;

    border: 0.75rem solid #fff;
}
.QuizVioletReponse span {
    font-size: 15rem;
    font-weight: 400;
    color: #d1d1d1;
}


.BoutonVioletValider {
    background: transparent -o-linear-gradient(260deg, #e6acd1 0%, #b5157c 100%);
    background: transparent linear-gradient(190deg, #e6acd1 0%, #b5157c 100%);
}

.PageVioletFooter {
    background: -o-linear-gradient(260deg, #e2d3e9 0%, #b5157c 50%, #e2d3e9 100%);
    background: linear-gradient(190deg, #e2d3e9 0%, #b5157c 50%, #e2d3e9 100%);
}

.QuizVioletCard.Dragging {
    z-index: -1;
    position: relative;
    width: 100rem;
    font-size: 7rem;
    height: auto;
}
.QuizVioletCard.Dragging .CardDrag {
    display: none;
}


@mixin quiz-violet-landscape-styles {
	.QuizVioletContainer > div {
        padding: 10rem 5rem;
        -ms-grid-rows: auto auto 12rem 10rem 1fr;
        grid-template-rows: auto auto 12rem 10rem 1fr;
        -ms-grid-columns : 1fr;
    }

    .QuizVioletTitre,
    .QuizVioletReponsesContainer {
        padding: 4rem;
        margin: 0rem 30rem 2rem;
    }
    .QuizVioletReponseTitre {
        font-size: 4rem;
        padding: 3rem;
    }
    .QuizVioletReponsesContainer {
        padding: 0rem;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
    .ResultatContainer .QuizVioletReponsesContainer {
        margin: 0;
    }

    .QuizVioletCards {
        -ms-grid-columns: 55rem 5rem 55rem 5rem 55rem 5rem 55rem;
        grid-template-columns: 55rem 55rem 55rem 55rem;
        -webkit-column-gap: 5rem;
           -moz-column-gap: 5rem;
                column-gap: 5rem;
        place-content: center;
        row-gap: 3rem;
        margin-bottom: 4rem;
    }

    .QuizVioletCard,
    .CardVioletGrise {
        height: 35rem;
        padding: 1.5rem 3rem;
        font-size: 3rem;
        border-radius: 4rem;
    }

    .QuizVioletCard{
        cursor: -webkit-grab;
        cursor: grab;
    }
    .QuizVioletReponse{
        cursor: pointer;
    }

    .QuizVioletReponses {
        height: 75rem;
        margin: 0rem 5rem;
        max-width: 100%;
    }

    .ResultatContainer .QuizVioletReponses {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin-top: 4rem;
    }

    .QuizVioletEnonce {
        min-height: 24rem;
        font-size: 4rem;
        margin: 2rem;
    }

    .QuizVioletCardReponse {
        width: 55rem;
        min-width: 45rem;
        height: 75rem;
        margin-right: 5rem;
        border-radius: 7rem;
    }
    .QuizVioletReponse span {
        font-size: 10rem;
    }

    .QuizVioletCardTitle {
        margin: 4rem auto 0rem;
    }
    .QuizVioletCardReponseList {
        margin: 2rem auto;
    }

    .QuizVioletCardTitle {
        height: 6rem;
        font-size: 2.5rem;
    }

    .QuizVioletCardTitle span {
        font-size: 8rem;
    }

    .QuizVioletReponse {
        margin: 2rem auto;
        width: 44rem;
        height: 22rem;
        font-size: 3.25rem;
    }

    .QuizVioletCard.Dragging {
        width: 43rem;
        font-size: 3rem;
        height: 36rem;
    }
}

@media (orientation: landscape) , (orientation: portrait) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @include quiz-violet-landscape-styles;
}

@supports (-ms-ime-align:auto) {
	@include quiz-violet-landscape-styles;
	
	
	.QuizVioletCard.Dragging {
		top:-9000px;
	}
}
