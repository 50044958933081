.QuizRoseContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding: 20rem;
    position: relative;
}

.QuizRoseQuestion {
    background: #dee0ea;
    text-align: center;
    color: #5c5555;
    padding: 15rem 10rem 10rem;
    border-radius: 5rem;
    font-size: 6rem;
    font-weight: 700;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    position: relative;

    margin-bottom: 10rem;
}

.QuizRoseQuestion:after {
    content: "";
    position: absolute;
    height: 2.5rem;
    background: transparent url('../../assets/images/Border-Rose.png') bottom center no-repeat;
    bottom: -0.5rem;
    width: 95%;
    background-size: contain;
    margin-left: 2.5rem;
    left: 0;
}

.QuizRoseReponses {
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -webkit-column-gap: 5rem;
       -moz-column-gap: 5rem;
            column-gap: 5rem;
    row-gap: 5rem;
    -ms-grid-columns: auto 5rem auto 5rem auto;
    grid-template-columns: auto auto auto;
    margin-bottom: 2rem;
}

.QuizRoseReponses button {
    background-color: #dfe1eb;
    border: 1.5rem solid #e73d5c;
    height: 22.5rem;
    border-radius: 17rem;
    font-weight: 700;
    font-size: 6.5rem;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .16);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .16);
    color: #575757;
}

.QuizRoseReponses button.Selected {
    background-color: #e73d5c;
    color: #fff;
    border-color: #dfe1eb;
}

.QuestionEnCoursRose {
    background: #e73d5c;
}

.QuizRoseReponsesContainer {
    padding: 2rem;
    margin-top: 15rem;
}

.ResultatContainer .QuizRoseReponsesContainer {
    padding: 20rem 25rem;
}

.Precisions {
    font-size: 5rem;
    font-weight: 700;
    margin: 2rem;
    text-align: center;
}

.ResultatContainer .Precisions {
    color: #fff;
}

.Source {
    font-size: 7rem;
    margin: 20rem 20rem 50rem;
    text-align: center;
}

.BoutonRoseValider {
    background: transparent -o-linear-gradient(260deg, #f5b0bb 0%, #e73d5c 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(190deg, #f5b0bb 0%, #e73d5c 100%) 0% 0% no-repeat padding-box;
}

.PageRoseFooter {
    background: -o-linear-gradient(260deg, #f5b0bb 0%, #e73d5c 50%, #f5b0bb 100%);
    background: linear-gradient(190deg, #f5b0bb 0%, #e73d5c 50%, #f5b0bb 100%);
}

@mixin quiz-rose-landscape-styles {
	.QuizRoseContainer {
        padding: 20rem 50rem;
    }
    .ResultatContainer .QuizRoseReponsesContainer{
        padding: 5rem 0rem;
    }

    .QuizRoseQuestion {
        border-radius: 6rem;
    }

    .QuizRoseQuestion:after {
        height: 3rem;
        bottom: -2rem;
    }

    .QuizRoseReponsesContainer {
        margin-top: 5rem;
    }

    .QuizRoseReponses {
        -ms-grid-columns: 42rem 42rem 42rem;
        grid-template-columns: 42rem 42rem 42rem;
        place-content: center;
    }

    .QuizRoseReponses button{
        height: 16rem;
        font-size: 7rem;
    }

    .Source {
        margin: 0rem 20rem;
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 4rem;
    }
}
	
	
@media (orientation: landscape) , (orientation: portrait) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @include quiz-rose-landscape-styles;
}

@supports (-ms-ime-align:auto) {
	@include quiz-rose-landscape-styles;
}
