html,
body {
  position: fixed;
  overflow: hidden;
}


html,
body,
button,
.Sensikit {
    font-family: "Montserrat", "Open Sans", sans-serif;
    -ms-scroll-chaining: none;
}

html {
    touch-action : none;
} 

body {
    overscroll-behavior: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

button:focus {
    outline: none;
}

button {
    cursor: pointer;
}

button:hover {
    -webkit-transform: scale(1.02);
        -ms-transform: scale(1.02);
            transform: scale(1.02);
}

.Sensikit {
    margin: auto;
    overflow: hidden;
    background-color: #fff;
    position: relative;
}

.Error {
    background-color: #000;
    color: white;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows : 1fr;
    place-items: center;
    font-size: 11rem;
}

@media (orientation: landscape) and (min-aspect-ratio: 16/9) {
    html {
        font-size: calc((9 / 16) * var(--vh, 1vh));
    }

    .Sensikit,
    .Error {
        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100);

        width: calc((16 / 9) * var(--vh, 1vh) * 100);
    }
}

@media (orientation: landscape) and (max-aspect-ratio: 16/9) {
    html {
        font-size: calc((9 / 29) * 1vw);
    }

    .Sensikit,
    .Error {
        height: calc((9 / 16) * 100vw);
        margin: calc((var(--vh, 1vh) * 100 - (9 / 16) * 100vw) / 2) 0;

        width: 100vw;
    }
}

@media (orientation: portrait) and (min-aspect-ratio: 9/16) {
    html {
        font-size: calc((9 / 29) * var(--vh, 1vh));
    }

    .Sensikit,
    .Error {
        height: calc(var(--vh, 1vh) * 100);
        width: calc((9 / 16) * var(--vh, 1vh) * 100);
    }
}

@media (orientation: portrait) and (max-aspect-ratio: 9/16) {
    html {
        font-size: calc((9 / 16) * 1vw);
    }

    .Sensikit,
    .Error {
        height: calc((16 / 9) * 100vw);
        margin-top: calc((100vh - (16 / 9) * 100vw) / 2);

        width: 100vw;
    }
}

.LogoContainer {
    display: -ms-grid;
    -ms-grid-row : 1;
    display: grid;
    -ms-grid-rows : 1fr;
    place-items: center;
}

.Title {
    font-size: 7rem;
    text-align: center;
    color: #000;
    font-weight: bold;
    padding: 5rem;
    border-radius: 2rem;
    margin: 0rem 0 4rem;
    font-family: "Montserrat", "Open Sans", sans-serif;
}

.LogoEntreprise {
    width: 80rem;
    height: 50rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    image-rendering: -webkit-optimize-contrast;
}

html,
body {
    background: #efefef;
    height: 100%; width: 100%;
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
}

.Hide {
    display: none !important;
}

.H-100 {
    height: 100%;
}

.Splashscreen {
    height: 100%;

    display: -ms-grid;

    display: grid;
    -ms-grid-rows: 1fr auto 1fr;
    grid-template-rows: 1fr auto 1fr;
    -ms-grid-columns : 1fr;
    position: relative;
}

.SplashscreenFooter,
.EndscreenFooter {
    background-color: #653ceb;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-row : 3;
    -ms-grid-columns : 1fr;
    place-items: center;
}

.SplashscreenFooter button,
.EndscreenFooter button {
    color: #fff;
    border-radius: 10rem;
    text-transform: uppercase;
    font-weight: bold;
    -webkit-box-shadow: 2px 2px 7px rgba(0, 0, 0, .18);
            box-shadow: 2px 2px 7px rgba(0, 0, 0, .18);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: none;
    font-size: 8rem;
}

.SplashscreenFooter button {
    background: #f59503;
    padding: 0 10rem 0 3rem;
    height: 18rem;
    border: 1px solid #fff;
}

.EndscreenFooter button {
    background: #f59503;
    border: 1.5rem solid #dcd0d0;
    padding: 3rem 6rem;
    margin: 8rem auto 0;
}

.BtnDownload  {
   display: flex;
    justify-content: space-around;
}

.BtnDownload a {
    margin-right: 4rem;
    font-size: 6rem;
    font-weight: 700;
}

.Logo {
    width: 140rem;
}

.LogoEnd {
    width: 120rem;
}

.StartButton {
    width: 12rem;
    border-radius: 100%;
    height: 12rem;
    margin-right: 4rem;
    background: #fff url("assets/images/Play.png") center no-repeat;
    background-size: 6rem;
}

.BandesContainer {
    position: relative;
    height: 18rem;
    -ms-grid-row : 2;
}

.Rayures {
    background: #fff url("assets/images/Bandes.png") center repeat-x;
    background-size: contain;
    image-rendering: pixelated;
}

.Splashscreen::before,
.EndScreen::before {
    content: "";
    position: absolute;
    background: transparent url("assets/images/Logo.png") center center;
    image-rendering: -webkit-optimize-contrast;

    left: 50%;
    width: 60rem;
    margin-left: -30rem;

    z-index: 10;
    background-size: contain;
    background-repeat: no-repeat;
}

.Splashscreen::before {
    top: 50%;
    height: 75rem;
    margin-top: -35rem;
}

.EndScreen::before {
    height: 42rem;
    top: 58%;
}

.Bandes {
    width: 100%;
}

.EndScreen {
    height: 100%;

    display: -ms-grid;

    display: grid;
    -ms-grid-rows: 206rem 1fr;
    grid-template-rows: 206rem 1fr;
    -ms-grid-columns : 1fr;
    position: relative;
}

.LogoSensiPlay {
    display: none;
}

.Download {
    width: 8rem;
    height: 10rem;
    background: url("assets/images/Download.png") center no-repeat;
    background-size: 7rem;
    margin: 0 0 0 4rem;
}

.Felicitations {
    font-size: 13rem;
    place-items: center;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    color: #515151;
    text-transform: uppercase;
    font-weight: bold;
}

.Remerciement {
    font-size: 9rem;
    place-items: center;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    color: #515151;
}

.Memo {
    font-size: 5rem;
    color: #fff;
    font-weight: bold;
    text-align: center;
    padding: 0 20rem;
    margin-top: 18rem;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    place-content: center;
}

.JLO {
    width: 60rem;
    margin: 0px 10rem;
}

.BlocHautEnd {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
}

.QuizSeparator {
    margin: 3rem 50rem;
    border-top: 1px solid #efefef;
}

.ReponsePrevContainer,
.ReponseNextContainer {
    position: absolute;
    top: 50%;
    z-index: 1;
}

.ReponsePrevContainer {
    left: -8rem;
}
.ReponseNextContainer {
    right: -8rem;
}

.ReponsePrev,
.ReponseNext {
    background: #fff;
    border-radius: 100%;
    width: 16rem;
    height: 16rem;
    -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
            box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0;
}

.ReponsePrev img,
.ReponseNext img {
    width: 8rem;
}
.ReponsePrev[disabled],
.ReponseNext[disabled] {
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
}

.CardDrag {
    position: absolute;
    background: #afaeae;
    border-radius: 100%;
    padding: 2rem;
    border: 1rem solid #fff;
    right: -5rem;
    bottom: -4rem;
    width: 8rem;
    height: 8rem;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    place-items: center;
    -webkit-box-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, .16);
            box-shadow: 0 0.5rem 0.75rem rgba(0, 0, 0, .16);
}
.CardDrag img {
    width: 6rem;
}

.QuestionEnCours {
    position: absolute;
    left: calc(50% - 5rem);
    top: -5rem;
    height: 12rem;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    place-content: center;
    width: 20rem;
    margin-left: -7.5rem;
    border: 1.5rem solid #dee0ea;
    color: #fff;
    border-radius: 3rem;
    font-weight: bold;
    font-size: 8rem;
}

.BoutonValider,
.BoutonSuivant {
    border: 1.5rem solid #fff;
    height: 20rem;
    min-width: 50rem;
    color: #fff;
    text-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .16);
    text-transform: uppercase;
    border-radius: 10rem;
    position: absolute;
    bottom: 10rem;
    left : calc(50% - 25rem);
    font-size: 7.5rem;
    font-weight: bold;

    transition: transform 0.2s ease;
}
.BoutonValider {
    border-color: #aaa;
}

.BoutonSuivant {
    border-color: #fff;
    width: 50rem;
}

.PageFooter {
    width: 100%;
    height: 1rem;
    position: absolute;
    bottom: 0;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    overflow: hidden;
}

.Link {
    text-decoration: none;
    color: #fff;
}



@mixin app-landscape-styles { 
	.Splashscreen::before {
        background: transparent url("assets/images/Logo-H.png") center center no-repeat;
        image-rendering: -webkit-optimize-contrast;

        width: 90rem;
        left: 50%;
        margin-left: -45rem;
        height: 40rem;
        top: 50%;
        margin-top: -17rem;
        background-size: contain;
    }

    .LogoEntreprise.Left {
        width: 80rem;
        height: 50rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        justify-self: left;
    }

    .LogoContainer.Left {
        display: -ms-grid;
        display: grid;
        grid-template-rows : 1fr;
        -ms-grid-rows : 1fr;
        -ms-grid-columns : 1fr;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }

    .Logo {
        width: 100rem;
    }

    .SplashscreenFooter button {
        padding: 0 10rem 0px 2rem;
        height: 10rem;
        font-size: 5rem;
    }

    .StartButton {
        width: 7rem;
        border-radius: 50%;
        height: 7rem;
        margin-right: 4rem;
        background-size: 4rem;
    }

    .ReponsePrev,
    .ReponseNext {
        display: none;
    }

    .CardDrag {
        right: -3rem;
        bottom: -3rem;
        width: 4rem;
        height: 4rem;
        border-width: 0.5rem;
    }

    .CardDrag img {
        width: 4rem;
    }

    .BoutonValider,
    .BoutonSuivant {
        height: 12.5rem;
        min-width: 50rem;
        font-size: 5rem;
        bottom: 4.5rem;
        border-width: 0.5rem;
        left : calc(50% - 25rem);
    }

    .PageFooter {
        height: 2rem;
    }

    .EndScreen {
        -ms-grid-rows: 105rem 1fr;
        grid-template-rows: 105rem 1fr;
        -ms-grid-columns : 1fr;
    }
    .EndScreen:before {
        display: none;
    }

    .Remerciement {
        font-style: italic;
    }

    .LogoSensiPlay {
        display: block;
    }
    .LogoSensiPlay img {
        max-width: 40rem;
        margin: 0 20rem;
    }

    .Memo {
        margin-top: 0rem;
        padding: 0;
    }

    .EndscreenFooter {
        -ms-grid-columns: auto 1fr auto;
        grid-template-columns: auto 1fr auto;
    }

    .EndscreenFooter button {
        margin: 4rem auto 0;
    }

    .SignatureContainer {
        display: -ms-grid;
        display: grid;
        -ms-grid-rows : 1fr;
        -ms-grid-columns : 1fr;
        height: 100%;
        place-content: end;
    }

    .JLO {
        margin-top: -30rem;
    }
}

@media (orientation: landscape) , (orientation: portrait) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @include app-landscape-styles;
}

@supports (-ms-ime-align:auto) {
    @include app-landscape-styles;
}

@media (orientation: portrait) {
	.DragDone:hover::after {
		display: none !important;
	}
}
