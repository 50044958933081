.PopupContainer {
    width: 100%;
    height: 100%;

    position: absolute;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    place-items: center;
    background: #3844436e;

    z-index: 2;
}

.Popup {
    width: 115rem;
    background: #2f3f4b;
    border-radius: 8rem;
    padding: 10rem;
    position: relative;
    border: 2rem solid #AAA;
}

.PopupRose {
    border-color: #e73d5c;
}

.PopupVert {
    border-color: #00a370;
}

.PopupOrange {
    border-color: #ed6b49;
}

.PopupBleu {
    border-color: #41a5b6;
}

.PopupViolet {
    border-color: #B5157C;
}

.TitrePopup {
    width: 105rem;
    font-size: 8rem;
    margin-bottom: 5rem;
    font-weight: bold;
    color: #f5b0bb;
}

.TitrePopupRose {
    color: #f5b0bb;
}

.TitrePopupVert {
    color: #d9e49b;
}

.TitrePopupOrange {
    color: #fad279;
}

.TitrePopupBleu {
    color: #cbe8f2;
}

.TitrePopupViolet {
    color: #e2d3e9;
}

.TextPopup {
    color: #fff;
    font-size: 7rem;
    margin-bottom: 15rem;
    font-weight: 500;
    white-space: pre-line;
}

.BtnOk,
.BtnInfo {
    position: absolute;
    width: 25rem;
    height: 25rem;
    padding: 0;
    border-radius: 100%;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    place-items: center;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, .16);
}

.BtnOk {
    font-size: 9rem;

    bottom: -12.5rem;
    left: 50%;
    margin-left: -12.5rem;
    border: 1.5rem solid #dcd0d0;

    color: #fff;
}

.BtnOkRose {
    background: #e73d5c;
}

.BtnOkVert {
    background: #40a370;
}

.BtnOkOrange {
    background: #ed6b49;
}

.BtnOkBleu {
    background: #41a5b6;
}

.BtnOkViolet {
    background: #B5157C;
}

.BtnInfo {
    font-size: 10rem;

    top: -12.5rem;
    right: -12.5rem;
    background: #fff;
    border: 1.5rem solid #AAA;
}

.BtnInfoRose {
    border-color: #e73d5c;
    color: #e73d5c;
}

.BtnInfoVert {
    border-color: #00a370;
    color: #00a370;
}

.BtnInfoBleu {
    border-color: #41a5b6;
    color: #41a5b6;
}

.BtnInfoViolet {
    border-color: #B5157C;
    color: #B5157C;
}

.BtnInfoOrange {
    border-color: #ed6b49;
    color: #ed6b49;
}

.Ronds {
    bottom: 12.5rem;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns: 20rem 3rem 20rem 3rem 20rem 3rem 20rem 3rem 20rem;
    grid-template-columns: 20rem 20rem 20rem 20rem 20rem;
    -webkit-column-gap: 3rem;
       -moz-column-gap: 3rem;
            column-gap: 3rem;
    position: absolute;
    place-items: center;
}

.RondPetit {
    width: 12rem;
    height: 12rem;
    opacity: 0.5;
}

.RondGros {
    width: 17.5rem;
    height: 17.5rem;
}


@mixin popup-landscape-styles {
	.TitrePopup {
        font-size: 6rem;
    }
    .TextPopup {
        font-size: 5rem;
        margin-bottom: 10rem;
    }
    .Ronds {
        -ms-grid-columns: 30rem 30rem 30rem 30rem 30rem;
        grid-template-columns: 30rem 30rem 30rem 30rem 30rem;
    }

    .RondPetit {
        width: 8rem;
        height: 8rem;
    }
    
    .RondGros {
        width: 13rem;
        height: 13rem;
    }
}

@media (orientation: landscape) , (orientation: portrait) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @include popup-landscape-styles;
}

@supports (-ms-ime-align:auto) {
	@include popup-landscape-styles;
}
