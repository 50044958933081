.QuizOrangeContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding: 15rem 20rem;
    position: relative;

    max-height: 100%;
    -ms-scroll-chaining: none;
        overscroll-behavior: none;
}

.QuizOrangeTitre {
    background: #ed6b49;
    font-size: 7.5rem;
    text-align: center;
    color: #fff;
    font-weight: bold;
    padding: 5rem;
    border-radius: 2rem;
    z-index: 1;
    margin-bottom: -1rem;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .16);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .16);
}

.QuizOrangeReponseContainer {
    min-width: 100%;
    max-height: 100%;
    background: #f5f5f5b3;
    -webkit-box-shadow: 2rem 2rem 4rem rgba(238, 224, 224, 0.1);
            box-shadow: 2rem 2rem 4rem rgba(238, 224, 224, 0.1);
    border-bottom-left-radius: 10rem;
    border-bottom-right-radius: 10rem;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    position: relative;
    padding-top: 1rem;
}

.QuizOrangeReponse {
    border-radius: 4rem;
    color: #534c4c;
    background-color: #fff;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .16);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .16);
    width: 75%;
    height: 84rem;
    margin: 10rem auto;
    border: 1.5rem dashed #ed6b49;
    font-size: 5.75rem;
    font-weight: 500;
    padding: 2rem;
}
.QuizOrangeReponse.DragDone {
    border: 1.5rem solid #fff;
}
.QuizOrangeReponse.DragActive {
    position: relative;
    background-color: #e2e2e2;
    color: #0000005c;
}
.QuizOrangeReponse.DragNone:after,
.QuizOrangeReponse.DragActive:after {
    content: "+";
    font-size: 15rem;
    font-weight: 200;
    position: absolute;
    width: 100%;
    height: 100%;
    display: -ms-grid;
    display: grid;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    place-content: center;
    color: #8f9194;
    top: 0;
    left: 0;
}
.QuizOrangeReponse.DragNone:not(.DragActive):after {
    color: #d1d1d1;
}

.QuizOrangeSentence,
.HideCard {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .16);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .16);
    border-radius: 4rem;
    font-size: 5rem;
    font-weight: 700;
    color: #707070;
    padding: 5rem;
    margin-bottom: 7.5rem;
    border: 0.5rem solid #ed6b49;
    background: transparent -o-radial-gradient(53% 50%, farthest-corner, #faf6d8 0%, #fad279 100%);
    background: transparent radial-gradient(farthest-corner at 53% 50%, #faf6d8 0%, #fad279 100%);
    position: relative;

    cursor: -webkit-grab;
    cursor: grab;
}

.BoutonOrangeValider {
    background: transparent -o-linear-gradient(260deg, #fad279 0%, #ed6b49 100%);
    background: transparent linear-gradient(190deg, #fad279 0%, #ed6b49 100%);
}

.PageOrangeFooter {
    background: -o-linear-gradient(260deg, #fad279 0%, #ed6b49 50%, #fad279 100%);
    background: linear-gradient(190deg, #fad279 0%, #ed6b49 50%, #fad279 100%);
}

.BoutonsOrange {
    position: absolute;
    bottom: 7.5rem;
    width: 100%;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns: 55rem 5rem 55rem;
    grid-template-columns: 55rem 55rem;
    place-content: center;
    -webkit-column-gap: 5rem;
       -moz-column-gap: 5rem;
            column-gap: 5rem;
}

.BoutonsOrange button {
    position: relative;
    bottom: 0;
    left: 0;
    top: 0;
    margin-left: 0;
}

.BoutonsOrange .BoutonEffacer {
    background: transparent -o-linear-gradient(259deg, #898585 0%, #332929 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(191deg, #898585 0%, #332929 100%) 0% 0% no-repeat padding-box;
}

.HideCard {
    display: none !important;
}

.QuizOrangeSentence.Dragging {
    z-index: -1;
    position: relative;
    width: 140rem;
    font-size: 7rem;
    height: fit-content;
}
.QuizOrangeSentence.Dragging .CardDrag {
    display: none;
}

.OrangeCopy{
    z-index: 99 !important;
}


@mixin quiz-orange-landscape-styles {
	.QuizOrangeContainer {
        padding: 10rem 20rem;
    }
    .ResultatContainer .QuizOrangeContainer {
        padding: 3rem 20rem;
    }

    .QuizOrangeTitre {
        width: 64rem !important;
        margin-left: auto;
        margin-right: auto;
        font-size: 4rem;
        padding: 3rem;
    }

    .QuizOrangeReponseContainer {
        width: 70rem !important;
        min-width: 70rem !important;
        max-width: 70rem !important;
        margin: auto;
        border-bottom-left-radius: 4rem;
        border-bottom-right-radius: 4rem;
    }

    .QuizOrangeReponse {
        height: 40rem;
        border-width: 1rem;
        margin: 4rem auto;
        font-size: 2.75rem;
    }

    .Sentences {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -ms-flex-pack: distribute;
            justify-content: space-around;
    }

    .QuizOrangeSentence,
    .HideCard {
        max-width: 40%;
        margin: 2rem 0;
        font-size: 3rem;
    }

    .QuizOrangeSentence.Dragging {
        font-size: 4rem;
        width: 40rem;
    }

    .BoutonsOrange {
        -ms-grid-columns: 40rem 50rem;
        grid-template-columns: 40rem 50rem;
        -webkit-column-gap: 20rem;
        -moz-column-gap: 20rem;
             column-gap: 20rem;
    }
}

@media (orientation: landscape) , (orientation: portrait) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    
    @include quiz-orange-landscape-styles;
}

@supports (-ms-ime-align:auto) {
    @include quiz-orange-landscape-styles;
	
	.QuizOrangeSentence.Dragging {
		top:-9000px;
	}
}