.QuizBleuSpacer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    height: 100%;
}
.QuizBleuContainer {
    padding: 15rem 30rem;
}

.QuizBleuQuestion {
    background: #dee0ea;
    text-align: center;
    color: #5c5555;
    padding: 15rem 10rem 10rem;
    border-radius: 6rem;
    font-size: 6rem;
    font-weight: 700;
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    position: relative;
    -webkit-box-shadow: 0px 1rem 1.5rem #0000002b;
            box-shadow: 0px 1rem 1.5rem #0000002b;

    margin-bottom: 10rem;
}
.QuizBleuQuestion:after {
    content: "";
    position: absolute;
    height: 2rem;
    background: transparent url('../../assets/images/Border-Bleu.png') bottom center no-repeat;
    bottom: -1.5rem;
    width: 90%;
    background-size: contain;
    margin-left: 5%;
    left: 0;
}
.ResultatContainer .QuizBleuQuestion:after{
    bottom: -1.25rem;
}

.QuestionEnCoursBleu {
    background: #41a5b6;
}

.QuizBleuReponse {
    margin: 4rem 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    padding-left: 12rem;
}

.QuizBleuReponse > input {
    position: absolute;
    left: 0;
    opacity: 0.01;
}

.QuizBleuReponse > label {
    background: #5c5555;
    color: #fff;
    border-radius: 3rem;
    font-size: 6rem;
    font-weight: bold;
    min-height: 7rem;
    width: 100%;
    margin-left: 3rem;
    padding: 2.5rem 5rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.QuizBleuReponse input:checked + label:after {
    display: -ms-grid;
    display: grid;
    grid-template-rows : 1fr;
    -ms-grid-rows : 1fr;
    -ms-grid-columns : 1fr;
    place-content: center;
}

.QuizBleuReponse input:checked + label:after {
    content: "✔";
    position: absolute;
    top: 0;
    left: -17rem;
    font-size: 1em;
    color: #f08254;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.QuizBleuReponse > label:after {
    content: "";
    position: absolute;
    left: -17rem;
    top: 0;
    width: 1.4em;
    height: 1.4em;
    border: 2rem solid #5c5555;
    border-radius: 4rem;
    background: #fff;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 0 rgba(203, 34, 237, 0.2);
            box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 0 rgba(203, 34, 237, 0.2);
    -webkit-transition: all 0.275s;
    -o-transition: all 0.275s;
    transition: all 0.275s;
}

.BoutonBleuValider {
    background: transparent -o-linear-gradient(256deg, #85b8ce 0%, #435c67 100%);
    background: transparent linear-gradient(194deg, #85b8ce 0%, #435c67 100%);
}

.PageBleuFooter {
    background: -o-linear-gradient(260deg, #cbe8f2 0%, #41a5b6 50%, #cbe8f2 100%);
    background: linear-gradient(190deg, #cbe8f2 0%, #41a5b6 50%, #cbe8f2 100%);
}


@mixin quiz-bleu-landscape-styles {
	.ResultatContainer .QuizBleuContainer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding: 5rem 30rem 0;
        height: 70rem;
    }

    .QuizBleuQuestion:after {
        height: 3rem;
        bottom: -2.5rem;
    }

    .ResultatContainer .QuizBleuQuestion {
        margin: 0 10rem 0 0;
        max-width: 45%;
    }

    .ResultatContainer .QuizBleuReponses {
        min-width: 55%;
    }

    .ResultatContainer .QuizBleuReponse > label {
        font-size: 5rem;
    }
}

@media (orientation: landscape) , (orientation: portrait) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @include quiz-bleu-landscape-styles;
}

@supports (-ms-ime-align:auto) {
	@include quiz-bleu-landscape-styles;
}
